import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from "axios";
import Box from '@mui/material/Box';
import { UserContext } from '../../Context/UserContext'

function PatientProfile() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [userId, setUserId] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [patientId, setPatientId] = useState('');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const { id } = useParams();

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true)
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false)
  };

  useEffect(() => {
    getPatientById();
  }, []);

  const getPatientById = async () => {
    let patientUserId = currentUser.userId;
    const response = await axios.get(`/profile/patient/${patientUserId}`);
    //console.log(response);
    setPatientId(response.data._id);
    setFirstName(response.data.userId.firstName);
    setLastName(response.data.userId.lastName);
    setEmail(response.data.userId.email);
    setUsername(response.data.userId.username);
    setPassword(response.data.userId.password);
    setConfirmPassword(response.data.userId.password);
    setPhone(response.data.phone);
    setAddress(response.data.address);
    setUserId(response.data.userId._id);
    setGender(response.data.gender);
    setDOB(response.data.dob);
  };

  const updatePatient = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`/patients/${patientId}`, {
        firstName,
        lastName,
        username,
        email,
        phone,
        password,
        confirmPassword,
        address,
        gender,
        dob,
        userId
      });
      navigate("/profile");
    } catch (error) {
      console.log(error.response.data.errors);
      //Display error message
      setErrorList(error.response.data.errors);
      handleDialogueOpen();
    }
  };


  useEffect(() => {
    if ((typeof password !== 'undefined') && password.length > 0 && password?.trim()?.length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    }
    else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    }
    else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h3 className="page-title">Hospitals</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <form id="addPatientForm" name='addPatientForm' onSubmit={updatePatient}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>International Hospital: Opposite MgM Hospital, Aurangabad, Maharashtra:7875315159 <span className="text-danger">*</span></label>
                      </div>
                    </div>
                   
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Ikon Hospital: Ganesh Colony: 9823405777 <span className="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Apple Hospital: Opposite MgM Hospital:024023223436<span className="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>MgM Hospital:Near Seven Hill in Cidco N 6:02406482000<span className="text-danger">*</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="m-t-20 text-center">
                    <button type="submit" className="btn btn-primary submit-btn">Update Profile</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Patient"
          ErrorList={errorList}
        />
      </div>
    </Box>
  )
}

export default PatientProfile;
